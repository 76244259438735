"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fundStaticCsvSchema = void 0;
const zod_1 = require("zod");
const fields_1 = require("../utils/fields");
const helpers_1 = require("../utils/helpers");
const row = zod_1.z.object({
    rowId: (0, fields_1.number)(),
    altman_fund_id: (0, fields_1.string)({ min: 20 }),
    pei_fund_id: (0, fields_1.string)({ type: zod_1.z.string().startsWith('fund:'), allowEmpty: true }),
    fund_name: (0, fields_1.string)({ min: 1 }),
    altman_fund_manager_id: (0, fields_1.string)({ min: 20 }),
    fund_vintage: (0, fields_1.number)({ allowEmpty: true, min: 1990, max: new Date().getFullYear() }),
    fund_size_amount: (0, fields_1.number)({ type: zod_1.z.coerce.number().positive(), allowEmpty: true }),
    altman_strategy_id_1: (0, fields_1.string)({ min: 20 }),
    altman_strategy_id_2: (0, fields_1.string)({ min: 20, allowEmpty: true }),
    altman_region_id_1: (0, fields_1.string)({ min: 20 }),
    altman_region_id_2: (0, fields_1.string)({ min: 20, allowEmpty: true }),
});
exports.fundStaticCsvSchema = {
    name: 'fund_static',
    row: row,
    collection: zod_1.z.array(zod_1.z.any()).superRefine((0, helpers_1.uniqueFields)('altman_fund_id', 'pei_fund_id')),
};
