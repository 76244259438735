import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import authentication from 'src/services/authentication';
import filterApi from 'src/services/filter-api';
import ErrorBoundary from 'src/components/ErrorBoundary';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100vh;
  background-color: steelblue;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 24rem;
  padding: 3rem;
  background-color: white;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
`;

const Input = styled.input`
  padding: 0.5em;
  margin: 0.25em;
`;

function AdminLoginPage({ onSubmit }) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState('');

  async function handleSubmit(event) {
    event.preventDefault();

    if (!email || !password) {
      return setError('You must fill in all fields');
    }

    try {
      const user = await authentication.signIn({ email, password });
      const currentUser = await filterApi.getUser(user.uid);

      if (currentUser.role !== 'admin') {
        throw new Error('Only admin users can access this app');
      }

      setEmail('');
      setPassword('');
      onSubmit(currentUser);
    } catch (err) {
      console.error(err);
      setError(err.message);
      onSubmit({ error: err.message });
    }
  }

  return (
    <Layout>
      <ErrorBoundary>
        <Form>
          <Input placeholder="email" onChange={event => setEmail(event.target.value)} />
          <Input placeholder="password" type="password" onChange={event => setPassword(event.target.value)} />
          <Input type="submit" onClick={handleSubmit} />
          <span>{error}</span>
        </Form>
      </ErrorBoundary>
    </Layout>
  );
}

AdminLoginPage.defaultProps = {
  onSubmit: () => {},
};

AdminLoginPage.propTypes = {
  onSubmit: PropTypes.func,
};

export default AdminLoginPage;
