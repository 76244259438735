import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import createPersistedState from 'use-persisted-state';
import { auth } from 'src/config/firebase';
import filterApi from 'src/services/filter-api';
import authentication from 'src/services/authentication';
import AdminLoginPage from 'src/pages/AdminLoginPage';
import Button from 'src/components/Button';
import NavLink from 'src/components/NavLink';

const usePersistedState = createPersistedState('currentUser');

function AdminTemplate({ children }) {
  const [currentUser, setCurrentUser] = usePersistedState(null);
  const [isLoginSubmitted, setIsLoginSubmitted] = useState(false);

  useEffect(
    function () {
      if (!isLoginSubmitted) return;

      const unsubscribe = auth.onAuthStateChanged(async function (authUser) {
        if (authUser === null) {
          return setCurrentUser(null);
        }

        const dbUser = await filterApi.getUser(authUser.uid);
        const isAuthorized = dbUser.role === 'admin';
        if (isAuthorized) {
          setCurrentUser(dbUser.email);
        }
      });

      return function cleanup() {
        unsubscribe();
      };
    },
    [isLoginSubmitted, setCurrentUser]
  );

  if (!currentUser) return <AdminLoginPage onSubmit={() => setIsLoginSubmitted(true)} />;
  return (
    <>
      <div
        css={`
          padding: 1em 2em;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 1em;
          color: black;
          border-bottom: 1px solid #e0e0e0;
        `}
      >
        <nav
          css={`
            display: flex;
            gap: 1em;
          `}
        >
          <NavLink to="/users">Users</NavLink>
          <NavLink to="/validations">Data Validation</NavLink>
        </nav>
        <div
          css={`
            display: flex;
            align-items: center;
            gap: 1em;
          `}
        >
          <span>{currentUser}</span>
          <Button
            size="xs"
            onClick={async () => {
              await authentication.signOut();
              setCurrentUser(null);
            }}
          >
            Sign Out
          </Button>
        </div>
      </div>
      <div>{children}</div>
    </>
  );
}

AdminTemplate.defaultProps = {};

AdminTemplate.propTypes = {
  children: PropTypes.any,
};

export default AdminTemplate;
