import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Spinner = styled.span`
  & > span {
    width: 6px;
    height: 6px;
    background-color: #333;

    border-radius: 100%;
    display: inline-block;

    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  & .bounce1 {
    animation-delay: -0.32s;
  }

  & .bounce2 {
    animation-delay: -0.16s;
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`;

function LoadingIndicator() {
  return (
    <Spinner>
      <span className="bounce1" />
      <span className="bounce2" />
      <span className="bounce3" />
    </Spinner>
  );
}

LoadingIndicator.defaultProps = {};

LoadingIndicator.propTypes = {
  children: PropTypes.any,
};

export default LoadingIndicator;
