import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
    console.error(errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h2>Something went wrong.</h2>
          <p>Try refreshing the page.</p>
          <p>
            If the problem persists please consider{' '}
            <a href="https://github.com/altman-ai/fund-filter/issues/new?assignees=&labels=&projects=&template=custom.md&title=%5BPage+Name%2FPanel+Name%5D+Brief+problem+title">
              filing a bug report
            </a>
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
