import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { validate, getSchema } from '@fundfilter/data-validations';
import { parse } from 'src/utils/csv';
import { read } from 'src/utils/file';
import Button from 'src/components/Button';
import ErrorBoundary from 'src/components/ErrorBoundary';
import DataTable from 'src/components/DataTable';
import FieldWithError from 'src/components/FieldWithError';
import { BsCheckSquareFill } from 'react-icons/bs';
import { FaFileCsv } from 'react-icons/fa';
import LoadingIndicator from 'src/components/LoadingIndicator';

const Layout = styled.div`
  padding: 2em;
`;

function convertToCSVString(data) {
  const header = Object.keys(data[0]).join(',');
  const rows = data.map(row => {
    return Object.values(row).join(',');
  });
  return [header, ...rows].join('\n');
}

function DataValidationPage() {
  const [targetFile, setTargetFile] = useState(null);
  const [schema, setSchema] = useState(null);
  const [validationResults, setValidationResults] = useState(null);
  const [validationCount, setValidationCount] = useState(0);
  const [inProgress, setInProgress] = useState(false);
  const [parseError, setParseError] = useState(null);

  async function handleSubmit(event) {
    event.preventDefault();
    setInProgress(true);

    try {
      const content = await read(targetFile);
      const { data } = await parse(content);
      const results = validate(data, schema);
      setValidationCount(results.length);
      setValidationResults(results.length > 1000 ? convertToCSVString(results) : results);
    } catch (error) {
      console.error(error);
      setParseError(error);
    } finally {
      setInProgress(false);
    }
  }

  function handleChangeFileInput(event) {
    setValidationResults(null);
    setValidationCount(0);
    setInProgress(false);
    const file = event.target.files[0];
    setTargetFile(file);

    try {
      setSchema(getSchema(file.name));
    } catch (error) {
      setSchema({ name: 'unknown' });
    }
  }

  const formReady =
    !validationResults && !['unknown' || undefined].includes(schema?.name) && targetFile?.type === 'text/csv';

  const formValidations = {
    file: {
      message: 'Invalid file type. Please upload a CSV file.',
      field: 'file',
      isError: targetFile ? targetFile.type !== 'text/csv' : false,
      isValid: targetFile ? targetFile.type === 'text/csv' : false,
    },
    schema: {
      field: 'schema',
      message:
        'Unknown schema. File names should start with the schema name. Currently only altman_fund_static and altman_fund_dynamic are supported.',
      isError: schema ? schema.name === 'unknown' : false,
      isValid: schema ? schema.name !== 'unknown' : false,
    },
  };

  const isValidated = !inProgress && validationResults !== null;
  const isValidatedWithErrors = isValidated && validationResults.length > 0;

  if (parseError) {
    return (
      <Layout>
        <h1>Parse Error</h1>
        <p>{parseError.message}</p>
      </Layout>
    );
  }
  return (
    <Layout>
      <ErrorBoundary>
        <form
          onSubmit={handleSubmit}
          css={`
            display: grid;
            max-width: 20rem;
            padding: 20px;
            border: 1px solid #ccc;
            gap: 20px;
          `}
        >
          <FieldWithError error={formValidations.file.isError && formValidations.file.message}>
            <input onChange={handleChangeFileInput} type="file" disabled={inProgress} />
          </FieldWithError>
          <FieldWithError error={formValidations.schema.isError && formValidations.schema.message}>
            <p
              css={`
                display: flex;
                align-items: center;
                gap: 0.5em;
              `}
            >
              {formValidations.schema.isValid && <BsCheckSquareFill color="#31c89e" />}Schema: {schema?.name}
            </p>
          </FieldWithError>
          <Button type="submit" disabled={!formReady || inProgress}>
            Vaidate
          </Button>
        </form>

        <div>
          {(inProgress || isValidated) && (
            <>
              <h4>Results {inProgress && <LoadingIndicator />}</h4>
              <p
                css={`
                  display: flex;
                  align-items: center;
                  gap: 0.5em;
                `}
              >
                <span
                  style={{
                    padding: '0.375em 0.5em',
                    backgroundColor: inProgress ? '#dfbe25' : validationResults.length ? '#dc3568' : '#31c89e',
                    fontSize: '0.675em',
                    color: 'white',
                  }}
                >
                  {inProgress
                    ? 'Validating...'
                    : validationResults.length
                    ? `Failed (${validationCount} violations)`
                    : 'Passed'}
                </span>{' '}
                {targetFile.name}
              </p>
            </>
          )}

          {isValidatedWithErrors ? (
            typeof validationResults === 'string' ? (
              <span
                css={`
                  display: flex;
                  align-items: center;
                  gap: 0.25em;
                `}
              >
                <FaFileCsv color="blue" />
                <a
                  href={'data:application/octet-stream,' + encodeURIComponent(validationResults)}
                  download={'validation-results-' + targetFile.name}
                >
                  Download results
                </a>
              </span>
            ) : (
              <DataTable
                data={validationResults}
                columns={[
                  {
                    Header: 'Row #',
                    accessor: 'rowId',
                  },
                  {
                    Header: 'Column',
                    accessor: 'field',
                  },
                  {
                    Header: 'Error',
                    accessor: 'message',
                  },
                ]}
              />
            )
          ) : null}
        </div>
      </ErrorBoundary>
    </Layout>
  );
}

DataValidationPage.defaultProps = {};

DataValidationPage.propTypes = {
  children: PropTypes.any,
};

export default DataValidationPage;
