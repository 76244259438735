import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import filterApi from 'src/services/filter-api';
import LoadingIndicator from 'src/components/LoadingIndicator';
import ErrorBoundary from 'src/components/ErrorBoundary';
import Table from './Table';

const Layout = styled.div`
  padding: 2em;
`;

function UsersPage() {
  const [isWorking, setIsWorking] = useState();

  const queryClient = useQueryClient();

  const { isLoading, isError, data, error } = useQuery('users', filterApi.getUsers);

  const mutation = useMutation(filterApi.updateUser, {
    onMutate() {
      setIsWorking(true);
    },
    onSettled() {
      queryClient.invalidateQueries('users');
      setTimeout(() => setIsWorking(false), 500);
    },
  });

  function handleUpdateUserData(userId, newData) {
    mutation.mutate({ ...newData, userId });
  }

  return (
    <Layout>
      <ErrorBoundary>
        <div>{(isLoading || isWorking) && <LoadingIndicator />}&nbsp;</div>
        <Table users={data} onUpdateUserData={handleUpdateUserData} />
        <div>
          {isError && <span>Error: {error.message}</span>}
          &nbsp;
        </div>
      </ErrorBoundary>
    </Layout>
  );
}

UsersPage.defaultProps = {};

UsersPage.propTypes = {
  children: PropTypes.any,
};

export default UsersPage;
