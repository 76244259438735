"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.foiaSourceAcronyms = void 0;
exports.foiaSourceAcronyms = [
    'AACRPS',
    'ACERA',
    'ACPP',
    'ACRP',
    'AFFRRF',
    'AUSPRS',
    'BCFPERS',
    'BCR',
    'BCRS',
    'CALPERS',
    'CCCERA',
    'CLWYD',
    'COGR',
    'CPPIB',
    'CRPTF',
    'CSPF',
    'CTPF',
    'DCCPF',
    'DCRB',
    'DPFPS',
    'ERPF',
    'ERSBC',
    'ERSRI',
    'ERST',
    'FCERA',
    'FCPF',
    'FFRET',
    'FPRF',
    'FSBA',
    'FWERF',
    'HCMT',
    'HCPF',
    'HFRRF',
    'HMEPS',
    'HPOPS',
    'INPRS',
    'ISBI',
    'KPERS',
    'LABFC',
    'LACERA',
    'LACERS',
    'LASERS',
    'LAWPERP',
    'LPF',
    'LSERS',
    'MCERS',
    'MFPRSI',
    'MMRB',
    'MODOTPERS',
    'MOSERS',
    'MPERS',
    'MPRIM',
    'MSBI',
    'MSRPS',
    'NCCPP',
    'NJDI',
    'NMERB',
    'NMPERA',
    'NMSIC',
    'NVPERS',
    'NYCBERS',
    'NYCERS',
    'NYCFDPF',
    'NYCPF',
    'OCERS',
    'OGB',
    'OPERS',
    'OPFPF',
    'OPPRS',
    'OST',
    'OTRS',
    'OUF',
    'PERSI',
    'PSERS',
    'PSRSSL',
    'RCBPS',
    'SAFPPF',
    'SBCERA',
    'SBCERS',
    'SDCERA',
    'SDCERS',
    'SERSO',
    'SFERS',
    'SPFO',
    'STRSOH',
    'SWIB',
    'SYPA',
    'TCDRS',
    'TMRS',
    'TRSIL',
    'TRSL',
    'TRSNYC',
    'TRST',
    'UHSE',
    'UKYE',
    'UMSE',
    'UTIMCO',
    'VCERA',
    'VPIC',
    'WSIB',
    'WVIMB',
    'WYPF',
];
