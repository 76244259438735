import React, { useMemo } from 'react';
// import { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTable, useSortBy } from 'react-table';

const Table = styled.table`
  border-collapse: collapse;
  border: 1px solid black;

  border-spacing: 0;
  border: 1px solid black;

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;

    :last-child {
      border-right: 0;
    }
  }
`;

const Th = styled.th`
  text-align: left;
`;

const Td = styled.td`
  text-align: left;
`;

function ValidationsTable({ data, columns }) {
  const serializedKeys = JSON.stringify(Object.keys(data[0]));

  const defaultColumns = useMemo(() => {
    return JSON.parse(serializedKeys).map(key => ({
      Header: key,
      accessor: key,
    }));
  }, [serializedKeys]);

  const tableInstance = useTable({ columns: columns || defaultColumns, data: data }, useSortBy);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => {
            const { key: headerGroupKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
            return (
              <tr key={headerGroupKey} {...headerGroupProps}>
                {headerGroup.headers.map(column => {
                  const { key: headerKey, ...headerProps } = column.getHeaderProps(column.getSortByToggleProps());

                  return (
                    <Th key={headerKey} {...headerProps}>
                      {column.render('Header')}
                      <span
                        css={`
                          font-size: 11px;
                        `}
                      >
                        {column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}
                      </span>
                    </Th>
                  );
                })}
              </tr>
            );
          })}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);

            const { key: rowKey, ...rowProps } = row.getRowProps();

            return (
              <tr key={rowKey} {...rowProps} title={row.original.userId}>
                {row.cells.map(cell => {
                  const { key: cellKey, ...cellProps } = cell.getCellProps();

                  return (
                    <Td key={cellKey} {...cellProps}>
                      {cell.render('Cell')}
                    </Td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

ValidationsTable.defaultProps = {
  data: [],
};

ValidationsTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array,
};

export default ValidationsTable;
